<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
    </b-alert>

    <div class="text-primary h2">
      <feather-icon
        icon="UserIcon"
        size="25"
        class="mr-0 mr-sm-50"
        variant="primary"
      />
      <span class="d-none d-sm-inline">Editar Agenda</span>
    </div>
    <b-row>
      <b-col
        cols="2"
        lg="2"
        md="2"
      >
        <user-edit-account
          :user-avatar="avatar"
          :user-data="userData"
          :vertical="true"
          class="mt-2 pt-75"
        />
      </b-col>
      <b-col
        cols="10"
        lg="10"
        md="10"
      >
        <user-edit-card
          v-if="userData.availabilities && holidays"
          :element-data="userData.availabilities"
          :holidays="holidays"
          class="mt-2 pt-75"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BCol, BRow,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import UserStoreModule from '../storeModule'
import UserEditAccount from './EditAccount.vue'
import UserEditCard from './EditCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditAccount,
    UserEditCard,
  },
  data() {
    return {
      userData: { availabilities: [] },
      jobList: [],
      idTypeList: [],
      departmentList: [],
      municipalityList: [],
      holidays: undefined,
      avatar: '',
      signature: '',
      USER_APP_STORE_MODULE_NAME: 'app-availabilities',

    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.registerModule(this.USER_APP_STORE_MODULE_NAME, UserStoreModule)

    this.fetchData()
    this.fetchHolidays()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.unregisterModule(this.USER_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetchHolidays() {
      const _self = this
      return store.dispatch(`${this.USER_APP_STORE_MODULE_NAME}/fetchHolidays`).then(response => {
        _self.holidays = response.data
        return true
      }).catch(error => {
        if (error.response.status === 404) {
          console.info('404', _self.holidays)
          _self.holidays = undefined
        }
        return false
      })
    },
    async fetchData() {
      const _self = this
      return store.dispatch(`${this.USER_APP_STORE_MODULE_NAME}/fetchData`, { id: router.currentRoute.params.id })
        .then(response => {
          _self.userData = response.data
          console.log(_self.userData.availabilities)
          if (_self.userData.avatar_id) {
            _self.fetchAvatar(_self.userData.avatar_id)
          }
          return true
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', _self.userData)
            _self.userData = undefined
          }
          return false
        })
    },
    fetchAvatar(avatar_id) {
      store.dispatch(`${this.USER_APP_STORE_MODULE_NAME}/fetchImage`, { id: avatar_id })
        .then(response => {
          this.avatar = response.data.img
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            userData = undefined
          }
        })
    },
    save(availabilities) {
      console.info('availabilities:', availabilities)

      const disabledDays = []
      for (const hol of this.holidays) {
        disabledDays.push(moment(hol.date, 'DD/MM/YYYY').toDate())
      }
      console.log(disabledDays)

      for (const disDay of disabledDays) {
        availabilities = availabilities.filter(element => !moment(element.calendar_date, 'DD/MM/YYYYTHH:mm:ss').isSame(moment(disDay), 'day'))
      }

      this.userData.availabilities = availabilities

      store.dispatch(`${this.USER_APP_STORE_MODULE_NAME}/editUser`, { id: router.currentRoute.params.id, userData: this.userData })
        .then(response => {
          this.$router.replace({ name: 'apps-availabilities-list' })
          if (response.data.consult_rescheduled) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Consultas por reprogramar',
                icon: 'SaveIcon',
                variant: 'success',
                text: 'En la agenda eliminada del médico se tenían citas programadas, el caso relacionado con estas citas se listara en el submenú "Reprogramar"',
              },
            })
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Usuario actualizado',
              icon: 'SaveIcon',
              variant: 'success',
              text: `El usuario ${this.userData._username}. Fue actualizado correctamente`,
            },
          })
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
  },
}
</script>

<style>

</style>
