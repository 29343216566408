<template>
  <b-card>
    <b-card-header>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <span class="font-weight-bold">{{ $t(resourcesElementName + '.name') }}</span>
      </b-col>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <b-col v-if="holidays">
              <Calendar
                :data-source="dataSource"
                :year="currentYear"
                :min-date="minDate"
                :language="language"
                :render-style="style"
                :custom-data-source-renderer="dayRender"
                :custom-day-renderer="allDaysRender"
                :allow-overlap="allowOverlap"
                :enable-range-selection="enableRangeSelection"
                :display-week-number="displayWeekNumber"
                :round-range-limits="roundRangeLimits"
                :always-half-day="alwaysHalfDay"
                :disabled-days="disabledDays"
                @select-range="selectRange"
              />
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="router().go(-1)"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
    <div>
      <b-modal
        id="modal-1"
        hide-footer
        :title="$t(resourcesElementName + '.object.edit_title')"
      >
        <template>
          <dates-edit-tab
            v-if="elementData"
            :element-data="currentDate"
            class="mt-2 pt-75"
          />
        </template>
      </b-modal>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BModal,
} from 'bootstrap-vue'
import Calendar from 'v-year-calendar'
import 'v-year-calendar/locales/v-year-calendar.es'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import router from '@/router'
import DatesEditTab from './EditDates.vue'

export default {
  components: {
    DatesEditTab, BModal, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, Calendar,
  },
  props: {
    elementData: {
      type: Array,
      required: true,
    },
    holidays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: 'availabilities',
      required,

      minDate: undefined,
      currentYear: new Date().getFullYear(),
      language: 'es',
      style: 'custom',
      allowOverlap: true,
      enableRangeSelection: true,
      displayWeekNumber: false,
      roundRangeLimits: false,
      alwaysHalfDay: false,
      dataSource: [],
      currentDate: {},
      disabledDays: [],
    }
  },
  computed: {
  },
  watch: {
    elementData(newValue, oldValue) {
      if (this.updateDates) {
        this.updateDates(newValue)
      }
    },
  },
  beforeDestroy() {
    this.$root.$off('cancel-dates')
    this.$root.$off('save-dates')
    this.$root.$off('remove-dates')
  },
  mounted() {
    this.$root.$on('cancel-dates', () => {
      this.updateDates(this.elementData)
      this.$root.$emit('bv::hide::modal', 'modal-1')
    })

    this.$root.$on('save-dates', contactData => {
      this.dataSource.pop()
      if (!this.currentDate || !this.currentDate.startDate || !this.currentDate.endDate) {
        return
      }
      this.currentDate.color = '#000000'
      const d = new Date(this.currentDate.startDate.getTime())
      for (d; d <= this.currentDate.endDate; d.setDate(d.getDate() + 1)) {
        let found = false
        for (const ava of this.elementData) {
          const date = new Date(ava.calendar_date)
          if (date.getFullYear() === d.getFullYear()
            && date.getMonth() === d.getMonth()
            && date.getDate() === d.getDate()
          ) {
            ava.initial_availability = this.currentDate.initial_availability
            ava.final_availability = this.currentDate.final_availability
            ava.initial_unavailability = this.currentDate.initial_unavailability
            ava.final_unavailability = this.currentDate.final_unavailability
            ava.observations = this.currentDate.observations
            found = true
            break
          }
        }
        if (!found) {
          const da = {
            calendar_date: new Date(d.getTime()),
            initial_availability: this.currentDate.initial_availability,
            final_availability: this.currentDate.final_availability,
            initial_unavailability: this.currentDate.initial_unavailability,
            final_unavailability: this.currentDate.final_unavailability,
            observations: this.currentDate.observations,
          }
          this.elementData.push(da)
        }
      }

      this.dataSource.push(this.currentDate)
      this.updateDates(this.elementData)
    })

    this.$root.$on('remove-dates', contactData => {
      this.dataSource.pop()
      if (!this.currentDate || !this.currentDate.startDate || !this.currentDate.endDate) {
        return
      }
      this.currentDate.color = '#000000'
      const d = new Date(this.currentDate.startDate.getTime())
      for (d; d <= this.currentDate.endDate; d.setDate(d.getDate() + 1)) {
        for (const ava of this.elementData) {
          const date = new Date(ava.calendar_date)
          if (date.getFullYear() === d.getFullYear()
            && date.getMonth() === d.getMonth()
            && date.getDate() === d.getDate()
          ) {
            console.log('found')
            this.elementData.splice(this.elementData.indexOf(ava), 1)
            break
          }
        }
      }
      const idx = this.dataSource.indexOf(this.currentDate)
      if (idx > -1) {
        this.dataSource.splice(idx, 1)
      }
      this.updateDates(this.elementData)
    })

    this.minDate = new Date()
  },
  methods: {
    router() {
      return router
    },
    dayRender(e, data) {
      e.classList.add('dayRender')
    },
    allDaysRender(e, data) {
      if (this.disabledDays.length < 1) {
        for (const hol of this.holidays) {
          this.disabledDays.push(moment(hol.date).toDate())
        }
      }
      for (const disDay of this.disabledDays) {
        const day = moment(data)
        if (day.isSame(moment(disDay), 'day')) {
          e.classList.add('holidayRender')
        }
      }
    },
    updateDates(newValue) {
      this.dataSource = []
      if (newValue) {
        for (const ava of newValue) {
          this.dataSource.push({
            startDate: new Date(ava.calendar_date),
            endDate: new Date(ava.calendar_date),
            color: '#000000',
          })
        }
      }
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$parent.save(this.elementData)
        }
      })
    },
    selectRange(e) {
      let state = 'new'
      this.currentDate = {
        startDate: e.startDate,
        endDate: e.endDate,
        color: '#999999',
        initial_availability: undefined,
        final_availability: undefined,
        initial_unavailability: undefined,
        final_unavailability: undefined,
        observations: undefined,
      }

      const d = new Date(e.startDate.getTime())
      for (d; d <= e.endDate; d.setDate(d.getDate() + 1)) {
        let found = false
        for (const ava of this.elementData) {
          const date = new Date(ava.calendar_date)
          if (date.getFullYear() === d.getFullYear()
            && date.getMonth() === d.getMonth()
            && date.getDate() === d.getDate()) {
            if (this.currentDate.initial_availability === undefined) {
              this.currentDate.initial_availability = ava.initial_availability
              this.currentDate.final_availability = ava.final_availability
              this.currentDate.initial_unavailability = ava.initial_unavailability
              this.currentDate.final_unavailability = ava.final_unavailability
              this.currentDate.observations = ava.observations
              state = 'single'
              found = true
            } else if (state !== 'many'
              && (this.currentDate.initial_availability !== ava.initial_availability
              || this.currentDate.final_availability !== ava.final_availability
              || this.currentDate.initial_unavailability !== ava.initial_unavailability
              || this.currentDate.final_unavailability !== ava.final_unavailability)
            ) {
              state = 'many'
              found = true
              this.currentDate.initial_availability = { HH: '07', mm: '00' }
              this.currentDate.final_availability = { HH: '18', mm: '00' }
              this.currentDate.initial_unavailability = { HH: '00', mm: '00' }
              this.currentDate.final_unavailability = { HH: '00', mm: '00' }
              this.currentDate.observations = ''
            }
          }
        }
        if (!found) {
          if (state !== 'single' && state != 'many') {
            state = 'new'
          } else {
            state != 'many'
          }
          this.currentDate.initial_availability = { HH: '07', mm: '00' }
          this.currentDate.final_availability = { HH: '18', mm: '00' }
          this.currentDate.initial_unavailability = { HH: '00', mm: '00' }
          this.currentDate.final_unavailability = { HH: '00', mm: '00' }
          this.currentDate.observations = ''
        }
      }

      this.currentDate.state = state

      this.dataSource.push(this.currentDate)
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
  },
}
</script>

<style>
 .calendar {
    overflow-x: visible !important;
 }
.dayRender {
   color: #0f6e7c !important;
 }
.holidayRender {
   color: red !important;
 }
</style>
