<template>
  <div>
    <validation-observer 
        ref="userForm"
        #default="{ invalid }"
        >
        <b-form
          @submit.prevent="save"
        >
        <!-- User Info: Input Fields -->
        <div :is="userData === undefined ? 'div' : 'b-card'">
          <div v-if="vertical">
            <b-row>
              <b-col class="b-avatar-square" cols="12" lg="12" md="12">
                <b-media>
                  <b-avatar
                      :src="avatar"
                      rounded
                      size="90%"
                      text=""
                      variant="light-success"
                  />
                </b-media>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
                  lg="12"
                  md="12"
              >
                <h2 class="mb-1 text-primary text-uppercase" style="font-size: 2.3vh">
                  <b>{{ userData.username }}</b>
                </h2>
                <h2 class="mb-1 text-primary text-uppercase" style="font-size: 2.3vh">
                  <b>{{ userData.firt_name }} {{ userData.middle_name }} {{ userData.surname_1 }} {{
                      userData.surname_2
                    }}</b>
                </h2>
              </b-col>
            </b-row>
          </div>
          <div v-else>
          <b-row>
            <b-col
                cols="12"
                md="12"
                lg="4"
            >
              <!-- Media -->
              <b-media>
                <template #aside>
                  <b-avatar
                      :src="avatar"
                      text=""
                      variant="light-success"
                      size="185px"
                      rounded
                  />
                </template>
              </b-media>
            </b-col>
            <b-col
                cols="12"
                md="12"
                lg="4"
            >

              <h2 class="mb-1 text-primary text-uppercase">
                <b>{{ userData.username }}</b>
              </h2>
              <h2 class="mb-1 text-primary text-uppercase">
                <b>{{ userData.firt_name }} {{ userData.middle_name }} {{ userData.surname_1 }} {{ userData.surname_2 }}</b>
              </h2>
            </b-col>
          </b-row>
          </div>
          </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, 
  BFormCheckbox, BInputGroup, BInputGroupAppend, BFormSelect, BBadge, BCollapse, BImg
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { avatarText } from '@core/utils/filter'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BBadge,
    BCollapse,
    BImg,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    userAvatar: {
      type: String,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // validation
      required,
      email,
      avatarUpdated: false,
      signatureUpdated: false,
      passwordConfirmation: "",
      medicalRecord: false,
      medicalRecordRules: null,
      occupationalMedicalLicenseRules: null,
      avatar: null,
      signature: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }  
  },
  watch: {
    userAvatar(newValue, oldValue) {
      this.avatar = newValue
    }
  },
  methods: {
    handleAvatarImage() {
      this.createAvatarBase64mage(this.$refs.refInputAvatar.files[0])
      this.avatarUpdated = true
    },
    createAvatarBase64mage(avatar) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.avatar = e.target.result
      }
      reader.readAsDataURL(avatar)
    },
    avatarText,
    save() {
      this.$refs.userForm.validate().then(success => {
        if (success) { 
          this.$parent.save(this.userData);
        }
      })
    }
  }
}
</script>

<style>
.b-avatar-square {
  width: 100%;
  position: relative;
  margin: 0 5%;
}

.b-avatar-square::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.b-avatar-square .b-avatar {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}


</style>

