<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">Horario de Disponibilidad</span>
      </div>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <b-col>
              <!-- Right Col: Table -->
              <label>{{ $t(resourcesElementName + '.object.available') }}</label>
              <vue-timepicker
                v-model="elementData.initial_availability"
                placeholder="Inicio de la jornada"
              />
            </b-col>

            <b-col>
              <span>{{ $t(resourcesElementName + '.object.until') }}</span>
              <vue-timepicker
                v-model="elementData.final_availability"
                placeholder="Fin de la jornada"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Right Col: Table -->
              <label>{{ $t(resourcesElementName + '.object.unavailable') }}</label>
              <vue-timepicker
                v-model="elementData.initial_unavailability"
                placeholder="Inicio de la no disponibilidad"
              />
            </b-col>

            <b-col>
              <span>{{ $t(resourcesElementName + '.object.until') }}</span>
              <vue-timepicker
                v-model="elementData.final_unavailability"
                placeholder="Fin de la no disponibilidad"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="observations"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.observations') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.observations')"
                >
                  <b-form-input
                    id="observations"
                    v-model="elementData.observations"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2 d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="hideModal()"
            >
              {{ $t('actions.cancel') }}
            </b-button>
            <b-button
              variant="warning"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
              @click="remove()"
            >
              {{ $t('actions.delete') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.accept') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueTimepicker, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'availabilities',
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('cancel-dates')
    },
    remove() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('remove-dates', _self.elementData)
          _self.$root.$emit('bv::hide::modal', 'modal-1')
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t('actions.result.delete.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t('actions.result.delete.success'),
            },
          })
        }
      })
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('save-dates', _self.elementData)
          _self.$root.$emit('bv::hide::modal', 'modal-1')
        }
      })
    },
  },
}
</script>

<style>

</style>
